<template>
  <div>
    <el-row>
      <el-col>
        <el-form :inline='true'>
          <el-form-item>
            <toolbar :buttonList="buttonList" @callFunction="callFunction"></toolbar>
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>
    <!-- 列表 -->
    <el-table
        :data="tableData"
        border
        style="width: 100%"
        v-loading="listLoading"
        ref="multipleTable"
        @current-change='currentChange'
        :highlight-current-row='true'
    >
      <el-table-column type='index' label="序号" width='80' align="center" :index='indexMethod'></el-table-column>
      <el-table-column prop="name" label="考位类型" width="" align="center" ></el-table-column>
      <el-table-column prop="extraDuration" label="额外时长" width="" align="center" >
	      <template #default="scope">
		      {{scope.row.extraDuration}}%
	      </template>
      </el-table-column>
      <el-table-column prop="" label="状态" width=""  align="center" >
        <template #default='scope' >
          <div >
            <el-tag v-if='scope.row.ddIdExamSeatStatus === 1' type="success">{{'启用'}}</el-tag>
            <el-tag v-if='scope.row.ddIdExamSeatStatus === 2' type="warning">{{'禁用'}}</el-tag>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="createTime" label="添加时间" width="" align="center" >
<!--        <template #default="scope">-->
<!--          {{scope.row.createTime.split('T')[0]}}&nbsp;{{scope.row.createTime.split('T')[1].split('.')[0]}}-->
<!--        </template>-->
      </el-table-column>
    </el-table>
    <!--底部工具条-->
    <el-col :span="24" class="pageBar">
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="pages.pageIndex"
          :page-sizes="pages.pageArr"
          :page-size="pages.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="pages.dataCount">
      </el-pagination>
    </el-col>
    <el-dialog
        :title= "editFalse?'编辑':'添加考位类型'"
        :visible.sync="dialogs.addType.dialog"
        width="30%"
        :close-on-click-modal='false'
    >
      <el-form :model="addForm"  ref="addFormRef" label-width="140px">
        <el-form-item label="考位类型：">
          <el-input v-model="addForm.profession" style="width: 80%"></el-input>
        </el-form-item>
        <el-form-item label="额外时长（%）：">
          <el-input v-model="addForm.time" style="width: 80%"></el-input>
        </el-form-item>
        <el-form-item label='状态：' prop='webUrl'>
          <template>
            <el-radio-group v-model="addForm.radioAdd">
              <el-radio :label="1">启用</el-radio>
              <el-radio :label="2">禁用</el-radio>
            </el-radio-group>
          </template>
        </el-form-item>
      </el-form>
      <span v-if="!editFalse" slot="footer" class="dialog-footer">
                <el-button @click="dialogs.addType.dialog = false">取 消</el-button>
                <el-button type="primary" @click="addConfirm" :loading="loading">确认</el-button>
            </span>
      <span v-else slot="footer" class="dialog-footer">
                <el-button @click="dialogs.addType.dialog = false">取 消</el-button>
                <el-button type="primary" @click="showConfirm" :loading="loading">确认</el-button>
            </span>
    </el-dialog>
    <el-dialog
        title= "提示"
        :visible.sync="dialogs.addType.confirm"
        width="30%"
        :close-on-click-modal='false'
    >
      <el-form   ref="addFormRef" label-width="100px">
        <el-form-item label="职业功能：">
          {{'该分类下的数据将停止使用”，确定禁用吗？'}}
        </el-form-item>
      </el-form>
      <span  slot="footer" class="dialog-footer">
                <el-button @click="dialogs.addType.confirm = false">取 消</el-button>
                <el-button type="primary" @click="editConfirm" :loading="loading">确认</el-button>
            </span>
    </el-dialog>
  </div>
</template>

<script>
import util from "../../../util/date";
import Toolbar from "../../components/Toolbar";
import { getButtonList } from "../../promissionRouter";
import {
  addExamIdentifyPoint,
  updateExamIdentifyPoint,
  getByNamespace,
  getExamSeatTypeList, addExamSeatType, updateExamSeatType,
} from '../../api/api'

export default {
  components:{Toolbar},
  data() {
    return {
      buttonList:[],
      tableData: [],
      areaArr: [],
      listLoading: false,
      //关于分页的obj
      pages: {
        pageSize: 20,//默认每页条数
        pageArr: [10, 20, 30, 40],//默认每页条数选择
        pageIndex: 1, //默认进入页
        dataCount: 20, //默认总页数
      },
      dialogs:{
        addType:{
          dialog:false,
          confirm:false,
        },
        confirm:false,
      },
      addForm:{
        profession:undefined,
        time:undefined,
        id:undefined,
        radioAdd:1,
      },
      loading:false,
      addFormRules:[],
      editFalse:false,
      pointName:[],
      grade:[],
      examPosition:[],
      skillTypes:[
        {value:1,name:'关键技能'},
        {value:2,name:'非关键技能'},
      ],
      registers:[],
      pointNums:[],
      nameSpaceTo:[],


    }
  },
  methods: {
    rowClass () {
      return 'padding:8px!important;'
    },
    callFunction(item) {
      this[item.func].apply(this, item);
    },
    getListData(){
      this.pages = {
        pageIndex: 1,
        pageSize: 20,
      }
      this.getDataList()
    },
    // 列表查询接口
    getDataList(){
      var params = {
        pageIndex: this.pages.pageIndex,//页码
        pageSize: this.pages.pageSize,//页数
      }
      this.listLoading = true
      getExamSeatTypeList(params).then(res => {
        var result = res.data
        if(result.success) {
          this.tableData = result.response.data
          this.pages.dataCount = result.response.dataCount
          // this.getNamespace('BizService.OpRoomService.ExamSeatStatus')
        }
        this.listLoading = false
      })
    },
    async handleAdd(){
      this.addForm={
        profession:undefined,
        time:undefined,
        id:undefined,
        radioAdd:1,
      }
      this.editFalse = false ;
      this.dialogs.addType.dialog = true;
    },
    async addConfirm(){
      let params = {
        ddIdExamSeatStatus:this.addForm.radioAdd,//考位状态 （字典命名空间：BizService.OpRoomService.ExamSeatStatus）
        name:this.addForm.profession,//考位类型名称
        extraDuration:this.addForm.time,//额外时间（百分比）
      }
      const res = await addExamSeatType(params)
      if (res.data.success){
        this.getListData()
        this.dialogs.addType.dialog = false ;
        this.$message({
          message:res.data.message,
          type:'success'
        })
      }else{
        this.$message({
          message:res.data.message,
          type:'warning'
        })
      }
    },
    handleEdit(){
      if (!this.needData){
        this.$message({
          message:'请选择一条数据',
          type:'warning'
        })
        return;
      }
      this.editFalse = true;
      this.addForm = {
        radioAdd:this.needData.ddIdExamSeatStatus,//考位状态 （字典命名空间：BizService.OpRoomService.ExamSeatStatus）
        profession:this.needData.name,//考位类型名称
        time:this.needData.extraDuration,//额外时间（百分比）
      }
      this.dialogs.addType.dialog = true;
    },
    showConfirm() {
      if (this.addForm.radioAdd === 2){
        this.dialogs.addType.confirm = true
      }else {
        this.editConfirm()
      }
    },
    async editConfirm(){
      let params = {
        ddIdExamSeatStatus:this.addForm.radioAdd,//考位状态 （字典命名空间：BizService.OpRoomService.ExamSeatStatus）
        name:this.addForm.profession,//考位类型名称
        extraDuration:this.addForm.time,//额外时间（百分比）
        id:this.needData.id,
      }
      const res = await updateExamSeatType(params)
      if (res.data.success){
        this.getDataList()
        this.dialogs.addType.dialog = false ;
        this.dialogs.addType.confirm = false ;
        this.$message({
          message:res.data.message,
          type:'success'
        })
      }else{
        this.$message({
          message:res.data.message,
          type:'warning'
        })
      }
      this.editFalse = false;
    },
    //禁用
    async disable(){
      if (!this.needData){
        this.$message({
          message:'请选择一条数据',
          type:'warning'
        })
        return;
      }
      this.addForm = {
        radioAdd:this.needData.ddIdExamSeatStatus,//考位状态 （字典命名空间：BizService.OpRoomService.ExamSeatStatus）
        profession:this.needData.name,//考位类型名称
        time:this.needData.extraDuration,//额外时间（百分比）
      }
      let params = {
        ddIdExamSeatStatus:2,//考位状态 （字典命名空间：BizService.OpRoomService.ExamSeatStatus）
        name:this.addForm.profession,//考位类型名称
        extraDuration:this.addForm.time,//额外时间（百分比）
        id:this.needData.id,
      }
      const res = await updateExamSeatType(params)
      if (res.data.success){
        this.getDataList()
        this.dialogs.addType.dialog = false ;
        this.dialogs.addType.confirm = false ;
        this.$message({
          message:res.data.message,
          type:'success'
        })
      }else{
        this.$message({
          message:res.data.message,
          type:'warning'
        })
      }
    },




    //获取字符空间
    async getNamespace(name){
      let params = {
        namespace:name,
      }
      const res = await  getByNamespace(params)
      console.log(res,'res')
      if (res.data.success){
        this.nameSpaceTo = res.data.response
      }
    },


    // 点击table某一行
    currentChange(val) {
      this.needData = val
      console.log(val,'val')
    },
    // 分页
    handleCurrentChange(val) {
      this.pages.pageIndex = val;
      this.getDataList();
    },
    handleSizeChange(val){
      this.pages.pageSize = val;
      this.getDataList();
    },
    // 时间格式化
    formatCreateTime: function(row, column) {
      return !row || row == ""
          ? ""
          : util.formatDate.format(new Date(row), "yyyy-MM-dd hh:mm");
    },
    // 表格数据分页条数累计
    indexMethod(index) {
      var currentPage = this.pages.pageIndex
      var pageSize = this.pages.pageSize
      return (index + 1) + (currentPage - 1) * pageSize
    },
  },
  watch: {

  },
  created(){
    this.utils = util
    let routers = window.localStorage.router
        ? JSON.parse(window.localStorage.router)
        : [];
    this.buttonList = getButtonList(this.$route.path, routers);
    this.getListData();
  },
}
</script>

<style lang="stylus" scoped>
.projectName {
  text-align: center;
}
.projectSelect {
  margin-bottom: 15px;
}
.projectTitle {
  margin-bottom: 15px
}
.replyBtn {
  margin: 10px 0;
  color: #409eff;
  cursor: pointer;
  width: 30px;
}
.replyMessage {
  background: #eee;
  line-height: 30px;
  padding: 5px;
  border-radius: 2px;
  margin: 5px 15px;
}
.titleTimer {
  margin-left: 10px;
}
.green_t {
  color: green;
}
.red_t {
  color: red;
}
</style>
